<template>
  <v-container fluid>
    <v-card style="position: relative">
      <v-skeleton-loader v-if="!isLoaded" type="table-row@10" class="pa-5"></v-skeleton-loader>
      <div v-else class="overflow-x-auto">
        <FormToolbar title="Моніторинг">
          <template v-slot:right>
            <v-switch color="primary" v-model="showAllTooltips" label="Показати підказки" hide-details class="mr-10" />
            <v-text-field
              v-model="search"
              class="shrink mr-10"
              append-icon="mdi-magnify"
              label="Пошук"
              single-line
              hide-details
              clearable
            />
          </template>
        </FormToolbar>
        <table>
          <tr>
            <th v-for="(th, index) in filteredTableHeader" :key="index" class="font-weight-medium">
              {{ th.title }}
            </th>
          </tr>
          <tr v-for="servicePoint in monitoringList" :key="servicePoint.id">
            <td>
              <v-tooltip :disabled="!showAllTooltips" top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="counter" v-on="on" v-bind="attrs">
                    <v-select
                      v-model.number="servicePoint.sort_number"
                      :items="spCounters"
                      @change="changeSpSortingNumber(servicePoint)"
                      class="custom-v-select"
                      dense
                      filled
                      hide-details
                    ></v-select>
                  </div>
                </template>
                <template v-slot:default>
                  <span>Порядок сортування торгових точок</span>
                </template>
              </v-tooltip>
            </td>

            <td>
              <div class="address" :style="`height: ${servicePoint.terminals.length * 160}px; min-height: 160px`">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <router-link
                        :to="{ name: 'ServicePointCard', params: { id: currentCompanyId, servicePointId: servicePoint.id } }"
                        class="text-decoration-none"
                      >
                        <div class="font-weight-medium">{{ servicePoint.name }}</div>
                        <div class="">
                          {{ servicePoint.address.city }}, {{ servicePoint.address.street }},
                          {{ servicePoint.address.building_number }}
                        </div>
                      </router-link>
                    </div>
                  </template>
                  <div>
                    <h4>Інформація про торгівельну точку:</h4>
                    <div class="text-center my-2">
                      <v-avatar v-if="servicePoint.balance_holder.logo">
                        <img :src="`${baseURL}/${servicePoint.balance_holder.logo}`" alt="logo" />
                      </v-avatar>
                    </div>
                    <p class="mb-0">
                      Орендодавець: <span class="font-weight-medium">{{ servicePoint.balance_holder.name }}</span>
                    </p>
                    <p class="mb-0">
                      Назва: <span class="font-weight-medium">{{ servicePoint.name }}</span>
                    </p>
                    <div class="d-flex justify-space-between">
                      <p class="mb-0 mr-1">Місце розташування:</p>
                      <div>
                        <template v-for="terminal in servicePoint.terminals">
                          <span :key="terminal.id" class="d-block font-weight-medium">{{ terminal.location }}</span>
                        </template>
                      </div>
                    </div>
                    <p class="mb-0">
                      Кількість поверхів: <span class="font-weight-medium">{{ servicePoint.floor_count }}</span>
                    </p>
                    <p class="mb-0">
                      Кількість жителів: <span class="font-weight-medium">{{ servicePoint.habitants_count }}</span>
                    </p>
                  </div>
                </v-tooltip>
                <div class="locations">
                  <div v-for="terminal in servicePoint.terminals" :key="terminal.id" class="location">
                    <span>{{ terminal.location }}</span>
                  </div>
                </div>
              </div>
            </td>

            <td v-if="!userRoles.includes(rolesDictionary.TECHNICIAN)">
              <div :class="[!servicePoint.terminals.length ? 'show-empty' : '', 'finance']">
                <div v-for="terminal in servicePoint.terminals" :key="terminal.id" class="finance-row">
                  <div class="finance-row-item">
                    <div v-if="terminal.state">
                      <v-tooltip :disabled="!showAllTooltips" right>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-if="terminal.state.balance.hasOwnProperty('current_cash')" class="mb-3" v-on="on" v-bind="attrs">
                            {{ terminal.state.balance.current_cash }}₴
                          </div>
                        </template>
                        <div class="text-tooltip">Сума готівки</div>
                      </v-tooltip>
                      <v-tooltip :disabled="!showAllTooltips" right>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-if="terminal.state.balance.hasOwnProperty('bills_count')" class="mb-8" v-on="on" v-bind="attrs">
                            {{ terminal.state.balance.bills_count }}
                            <img :src="require('../../assets/images/cash.svg')" alt="cash" />
                          </div>
                        </template>
                        <div class="text-tooltip">Кількість купюр в купюроприймачі</div>
                      </v-tooltip>
                      <v-tooltip :disabled="!showAllTooltips" right>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-if="terminal.state.balance.hasOwnProperty('total_cash')" v-on="on" v-bind="attrs">
                            {{ terminal.state.balance.total_cash }}
                          </div>
                        </template>
                        <div class="text-tooltip">
                          Загальна кількість готівки та безготівки з моменту встановлення купюроприймача
                        </div>
                      </v-tooltip>
                    </div>
                  </div>
                  <!--                  <div class="finance-row-item">
                    <div v-if="terminal.state.collection_time" class="mb-5">{{ terminal.state.balance.collection_time }}</div>
                    <div v-if="terminal.state.collection_sum">{{ terminal.state.balance.collection_sum }}₴</div>
                  </div>
                  <div class="finance-row-item">
                    <div v-if="terminal.state.prev_collection_time" class="mb-5">
                      {{ terminal.state.balance.prev_collection_time }}
                    </div>
                    <div v-if="terminal.state.prev_collection_sum">{{ terminal.state.balance.prev_collection_sum }}₴</div>
                  </div>-->
                </div>
              </div>
            </td>

            <td v-if="!userRoles.includes(rolesDictionary.ACCOUNTANT)">
              <div :class="[!servicePoint.terminals.length ? 'show-empty' : '', 'terminal']">
                <template v-if="servicePoint.terminals.length">
                  <div v-for="terminal in servicePoint.terminals" :key="terminal.id" class="terminal-row">
                    <div class="terminal-row-item">
                      <div class="terminal-row-item__header">
                        <div>
                          <v-btn @click="sendCommand(terminal.id, 'REQUEST_STATUSES')" icon>
                            <v-icon :color="getTerminalColor(terminal)"> mdi-refresh </v-icon>
                          </v-btn>
                        </div>
                        <router-link
                          :to="{ name: 'TerminalCard', params: { id: currentCompanyId, terminalId: terminal.id } }"
                          :style="{ color: getTerminalColor(terminal) }"
                          v-add-class-on-hover="'text-decoration-underline'"
                          class="text-decoration-none"
                        >
                          <span>T {{ terminal.id }}</span>
                        </router-link>
                        <div v-if="terminal.state">
                          <v-tooltip :disabled="!showAllTooltips" top>
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                v-show="terminal.state.status.is_blocked"
                                :src="require('../../assets/images/lock.svg')"
                                alt="lock"
                                v-bind="attrs"
                                v-on="on"
                              />
                            </template>
                            <div class="text-tooltip">Термінал заблокований</div>
                          </v-tooltip>
                        </div>
                      </div>
                      <div :style="{ color: getTerminalColor(terminal) }" class="terminal-row-item__imei">
                        <v-tooltip :disabled="!showAllTooltips" top open-delay="200">
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">{{ terminal.imei }}</div>
                          </template>
                          <div>
                            <h4>Версії ПЗ:</h4>
                            <p class="mb-0">
                              Протокол: <span class="font-weight-medium">{{ terminal.state.info.protocol_version }}</span>
                            </p>
                            <p class="mb-0">
                              Версія плати термінала:
                              <span class="font-weight-medium">{{ terminal.state.info.pcb }}</span>
                            </p>
                            <p class="mb-0">
                              Версія прошивки:
                              <span class="font-weight-medium">{{ terminal.state.info.firmware }}</span>
                            </p>
                            <p class="mb-0">
                              Версія бутлоадера:
                              <span class="font-weight-medium">{{ terminal.state.info.bootloader }}</span>
                            </p>
                            <p class="mb-0">
                              Версія каналу зв'язку:
                              <span class="font-weight-medium">{{ terminal.state.info.channel }}</span>
                            </p>
                            <p class="mb-0">
                              Версія модуля радіозв'язку:
                              <span class="font-weight-medium">{{ terminal.state.info.radio }}</span>
                            </p>
                          </div>
                        </v-tooltip>
                      </div>
                      <div
                        v-if="terminal.state"
                        :style="{ color: getTerminalColor(terminal) }"
                        class="terminal-row-item__status mt-5"
                      >
                        <span v-if="terminal.state.status.is_online">
                          {{ terminal.state.status.cb_status | getCbStatusTitle }}
                        </span>
                        <span v-else>Немає зв'язку</span>
                      </div>
                      <!--                      <div class="terminal-row-item__statuses d-flex"></div>-->
                    </div>
                    <div class="terminal-row-item right">
                      <div
                        v-if="terminal.state"
                        :style="{
                          color: terminal.state.status.is_online
                            ? terminal.state.status.time_error
                              ? '#FF103B'
                              : getTerminalColor(terminal)
                            : 'grey',
                          flexWrap: terminal.state.status.time_error ? 'wrap' : 'nowrap',
                        }"
                      >
                        <span v-if="terminal.state.status.time_error" class="d-block" style="width: 100%"> server time </span>
                        <v-tooltip :disabled="!showAllTooltips" top>
                          <template v-slot:activator="{ on, attrs }">
                            <div class="d-flex justify-space-around" style="width: 100%" v-bind="attrs" v-on="on">
                              <span>{{ terminal.state.status.last_packet_time | getShortDate }}</span>
                              <span>{{ terminal.state.status.last_packet_time | getShortTime }}</span>
                            </div>
                          </template>
                          <div class="text-tooltip">Час останнього оновлення</div>
                        </v-tooltip>
                      </div>
                      <div v-if="terminal.state">
                        <div>
                          <v-tooltip v-if="terminal.state.status.pos_status" top open-delay="200">
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="px-2">
                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M11.9167 0.758333H1.08333C0.903841 0.758333 0.758333 0.903841 0.758333 1.08333V11.9167C0.758333 12.0962 0.903841 12.2417 1.08333 12.2417H11.9167C12.0962 12.2417 12.2417 12.0962 12.2417 11.9167V1.08333C12.2417 0.903841 12.0962 0.758333 11.9167 0.758333ZM1.08333 0C0.485025 0 0 0.485025 0 1.08333V11.9167C0 12.515 0.485025 13 1.08333 13H11.9167C12.515 13 13 12.515 13 11.9167V1.08333C13 0.485025 12.515 0 11.9167 0H1.08333Z"
                                    :fill="getPosTerminalIconColor(terminal)"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M10.8333 0.541667H2.16667V5.41667C2.16667 5.71582 2.40918 5.95833 2.70833 5.95833H10.2917C10.5908 5.95833 10.8333 5.71582 10.8333 5.41667V0.541667ZM1.625 0V5.41667C1.625 6.01498 2.11002 6.5 2.70833 6.5H10.2917C10.89 6.5 11.375 6.01498 11.375 5.41667V0H1.625Z"
                                    :fill="getPosTerminalIconColor(terminal)"
                                  />
                                  <path
                                    d="M3.54622 4.99512H9.47071C9.54927 4.99512 9.62462 4.96391 9.68017 4.90835C9.73572 4.8528 9.76693 4.77746 9.76693 4.69889V1.73665C9.76693 1.65809 9.73572 1.58274 9.68017 1.52719C9.62462 1.47164 9.54927 1.44043 9.47071 1.44043H3.54622C3.46766 1.44043 3.39231 1.47164 3.33676 1.52719C3.28121 1.58274 3.25 1.65809 3.25 1.73665V4.69889C3.25 4.77746 3.28121 4.8528 3.33676 4.90835C3.39231 4.96391 3.46766 4.99512 3.54622 4.99512ZM3.84245 2.03288H9.17448V4.40267H3.84245V2.03288Z"
                                    :fill="getPosTerminalIconColor(terminal)"
                                  />
                                  <path
                                    d="M5.05708 8.86529C5.02495 8.88727 4.99747 8.91537 4.97621 8.94798C4.95494 8.98059 4.94032 9.01708 4.93316 9.05534C4.92601 9.09361 4.92647 9.13292 4.93452 9.17101C4.94257 9.2091 4.95804 9.24523 4.98007 9.27733C5.01452 9.32633 5.03216 9.38517 5.03034 9.44504C5.02853 9.50491 5.00735 9.56258 4.96999 9.6094C4.92172 9.67139 4.90004 9.75001 4.90974 9.82798C4.91943 9.90595 4.9597 9.97687 5.02168 10.0251C5.08367 10.0734 5.1623 10.0951 5.24026 10.0854C5.31823 10.0757 5.38915 10.0354 5.43743 9.97346C5.55175 9.82731 5.61645 9.64849 5.6221 9.46303C5.62774 9.27756 5.57405 9.09514 5.46883 8.9423C5.42444 8.87749 5.35612 8.83297 5.27891 8.81853C5.20169 8.80408 5.1219 8.8209 5.05708 8.86529V8.86529Z"
                                    :fill="getPosTerminalIconColor(terminal)"
                                  />
                                  <path
                                    d="M6.20965 8.45334C6.1886 8.41976 6.16103 8.39075 6.12856 8.36802C6.0961 8.34529 6.0594 8.3293 6.02065 8.32101C5.9819 8.31272 5.94187 8.31229 5.90295 8.31974C5.86402 8.3272 5.82699 8.34238 5.79404 8.36441C5.7611 8.38643 5.7329 8.41485 5.71114 8.44796C5.68937 8.48108 5.67446 8.51823 5.66731 8.55721C5.66016 8.59619 5.6609 8.63621 5.66949 8.6749C5.67808 8.71359 5.69435 8.75016 5.71733 8.78245C5.85346 8.98611 5.9229 9.22705 5.91606 9.47193C5.90921 9.7168 5.82641 9.95349 5.67912 10.1492C5.65578 10.1803 5.6388 10.2158 5.62914 10.2534C5.61949 10.2911 5.61735 10.3303 5.62285 10.3689C5.62835 10.4074 5.64138 10.4444 5.6612 10.4779C5.68102 10.5114 5.70724 10.5406 5.73836 10.5639C5.76948 10.5873 5.8049 10.6043 5.84258 10.6139C5.88026 10.6236 5.91948 10.6257 5.95799 10.6202C5.9965 10.6147 6.03355 10.6017 6.06702 10.5819C6.10049 10.562 6.12973 10.5358 6.15307 10.5047C6.37376 10.2107 6.49773 9.85547 6.50786 9.48802C6.518 9.12057 6.4138 8.75904 6.20965 8.45334V8.45334Z"
                                    :fill="getPosTerminalIconColor(terminal)"
                                  />
                                  <path
                                    d="M6.95081 7.95945C6.92928 7.92705 6.90157 7.8992 6.86928 7.8775C6.83699 7.8558 6.80074 7.84068 6.7626 7.83299C6.72446 7.8253 6.68518 7.8252 6.647 7.83269C6.60882 7.84019 6.5725 7.85512 6.54009 7.87666C6.50769 7.89819 6.47984 7.92589 6.45814 7.95818C6.43645 7.99048 6.42132 8.02673 6.41363 8.06487C6.40595 8.103 6.40584 8.14228 6.41334 8.18046C6.42083 8.21864 6.43577 8.25497 6.4573 8.28737C6.69459 8.64483 6.81551 9.06693 6.80347 9.49581C6.79143 9.92469 6.64703 10.3394 6.39006 10.6829C6.36671 10.7141 6.34973 10.7495 6.34008 10.7872C6.33042 10.8248 6.32829 10.8641 6.33379 10.9026C6.33929 10.9411 6.35232 10.9781 6.37214 11.0116C6.39196 11.0451 6.41818 11.0743 6.4493 11.0977C6.48042 11.121 6.51583 11.138 6.55352 11.1476C6.5912 11.1573 6.63042 11.1594 6.66893 11.1539C6.70744 11.1484 6.74449 11.1354 6.77796 11.1156C6.81143 11.0957 6.84067 11.0695 6.86401 11.0384C7.19445 10.5969 7.38018 10.0639 7.39572 9.51269C7.41126 8.96144 7.25585 8.41888 6.95081 7.95945V7.95945Z"
                                    :fill="getPosTerminalIconColor(terminal)"
                                  />
                                  <path
                                    d="M7.27873 7.38498C7.24633 7.40655 7.21851 7.43428 7.19684 7.4666C7.17517 7.49892 7.16008 7.5352 7.15243 7.57335C7.14478 7.61151 7.14473 7.6508 7.15227 7.68897C7.15981 7.72715 7.1748 7.76346 7.19638 7.79585C7.53613 8.30575 7.7094 8.90847 7.69232 9.52096C7.67523 10.1335 7.46864 10.7256 7.10099 11.2158C7.07765 11.2469 7.06067 11.2823 7.05102 11.32C7.04136 11.3577 7.03922 11.3969 7.04473 11.4354C7.05023 11.4739 7.06326 11.5109 7.08308 11.5444C7.1029 11.5779 7.12912 11.6071 7.16024 11.6305C7.19136 11.6538 7.22677 11.6708 7.26445 11.6804C7.30214 11.6901 7.34135 11.6922 7.37986 11.6867C7.41837 11.6812 7.45542 11.6682 7.4889 11.6484C7.52237 11.6286 7.55161 11.6023 7.57495 11.5712C8.01593 10.9829 8.26361 10.2723 8.28388 9.53727C8.30415 8.80228 8.096 8.07909 7.68811 7.46733C7.64458 7.40233 7.5771 7.35718 7.5004 7.34176C7.4237 7.32633 7.34401 7.34187 7.27873 7.38498V7.38498Z"
                                    :fill="getPosTerminalIconColor(terminal)"
                                  />
                                  <path
                                    d="M6.21216 2.92188H6.80461V3.51432H6.21216V2.92188Z"
                                    :fill="getPosTerminalIconColor(terminal)"
                                  />
                                  <path
                                    d="M7.39722 2.92188H7.98967V3.51432H7.39722V2.92188Z"
                                    :fill="getPosTerminalIconColor(terminal)"
                                  />
                                  <path
                                    d="M5.02734 2.92188H5.61979V3.51432H5.02734V2.92188Z"
                                    :fill="terminal.state.status.pos_colour || 'grey'"
                                  />
                                </svg>
                              </div>
                            </template>
                            <span>{{ terminal.state.status.pos_status }}</span>
                          </v-tooltip>
                        </div>
                        <div class="ml-10">
                          <v-tooltip v-if="terminal.state.status.ba_status" top open-delay="200">
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="px-2">
                                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M11.8529 0H1.14706C0.514571 0 0 0.473133 0 1.05469V4.92188C0 5.50343 0.514571 5.97656 1.14706 5.97656H3.26274V10.9453C3.26274 11.5269 3.77732 12 4.4098 12H8.5902C9.22268 12 9.73725 11.5269 9.73725 10.9453V5.97656H11.8529C12.4854 5.97656 13 5.50343 13 4.92188V1.05469C13 0.473133 12.4854 0 11.8529 0ZM4.4098 11.2969C4.19897 11.2969 4.02745 11.1392 4.02745 10.9453L4.02748 9.42628V2.22656H3.26274V5.27344H1.14706C0.936229 5.27344 0.764706 5.11573 0.764706 4.92188V1.05469C0.764706 0.860836 0.936229 0.703125 1.14706 0.703125H11.8529C12.0638 0.703125 12.2353 0.860836 12.2353 1.05469V4.92188C12.2353 5.11573 12.0638 5.27344 11.8529 5.27344H9.73725V2.22656H8.97257L8.97255 9.42628V10.9453C8.97255 11.1392 8.80103 11.2969 8.5902 11.2969H5.26283H4.4098Z"
                                    :fill="getBillAcceptorIconColor(terminal)"
                                  />
                                  <rect
                                    x="1.61206"
                                    y="1.55273"
                                    width="9.8266"
                                    height="0.705874"
                                    rx="0.352937"
                                    :fill="getBillAcceptorIconColor(terminal)"
                                  />
                                  <path
                                    d="M6.50017 7.47656C7.38565 7.47656 8.10605 6.81417 8.10605 6C8.10605 5.18583 7.38565 4.52344 6.50017 4.52344C5.61469 4.52344 4.89429 5.18583 4.89429 6C4.89429 6.81417 5.61469 7.47656 6.50017 7.47656ZM6.50017 5.22656C6.96399 5.22656 7.34135 5.57353 7.34135 6C7.34135 6.42647 6.96399 6.77344 6.50017 6.77344C6.03635 6.77344 5.65899 6.42647 5.65899 6C5.65899 5.57353 6.03635 5.22656 6.50017 5.22656Z"
                                    :fill="getBillAcceptorIconColor(terminal)"
                                  />
                                  <path
                                    d="M6.7125 8.70769C6.51431 8.58774 6.26238 8.65988 6.16289 8.83449C6.01298 9.10404 6.26017 9.39893 6.57482 9.34472C6.91122 9.28029 6.99259 8.88179 6.7125 8.70769Z"
                                    :fill="getBillAcceptorIconColor(terminal)"
                                  />
                                  <path
                                    d="M6.12515 3.06865C6.16751 3.25632 6.36078 3.38161 6.57479 3.34475C6.78213 3.30502 6.91458 3.12537 6.87507 2.93131C6.82692 2.72002 6.59819 2.6051 6.3892 2.66365C6.19354 2.72107 6.08931 2.89259 6.12515 3.06865Z"
                                    :fill="getBillAcceptorIconColor(terminal)"
                                  />
                                </svg>
                              </div>
                            </template>
                            <div>
                              <h4>Купюроприймач:</h4>
                              <p class="mb-0">
                                Статус: <span class="font-weight-medium">{{ terminal.state.status.ba_status }}</span>
                              </p>
                              <p class="mb-0">
                                Заповненість:
                                <span class="font-weight-medium">{{ terminal.state.balance.bill_acceptor_load }}%</span>
                              </p>
                              <p class="mb-0">
                                Купюр в купюрнику:
                                <span class="font-weight-medium">{{ terminal.state.balance.bills_count }}</span>
                              </p>
                            </div>
                          </v-tooltip>
                        </div>
                        <span>{{ terminal.state.balance.bill_acceptor_load }}%</span>
                      </div>
                      <div>
                        <div v-if="terminal.state">
                          <v-tooltip top open-delay="200">
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="mobile">{{ terminal.state.info.modem_phone_num }}</div>
                            </template>
                            <div>
                              <h4>Зв’язок:</h4>
                              <p class="mb-0">
                                Тип зв’язку: <span class="font-weight-medium">{{ terminal.state.info.network_type }}</span>
                              </p>
                              <p class="mb-0">
                                Рівень: <span class="font-weight-medium">{{ terminal.state.status.rssi }}dB</span>
                              </p>
                              <p class="mb-0">
                                Телефон модема:
                                <span class="font-weight-medium">{{ terminal.state.info.modem_phone_num }}</span>
                              </p>
                              <p class="mb-0">
                                Грошей на рахунку:
                                <span class="font-weight-medium">{{ terminal.state.info.modem_balance }} грн.</span>
                              </p>
                              <p class="mb-0">
                                GB на рахунку:
                                <span class="font-weight-medium">{{ terminal.state.info.modem_available_traffic }} GB</span>
                              </p>
                            </div>
                          </v-tooltip>
                        </div>
                        <div class="network">
                          <span v-if="terminal.state">
                            {{ terminal.state.status.rssi }}
                            <img :src="require('../../assets/images/network.svg')" alt="network" />
                          </span>
                          <span v-if="terminal.state" class="ml-auto">{{ terminal.state.info.network_type }}</span>
                        </div>
                        <!--                        <div class="report">
                          <span v-if="terminal.state">{{ terminal.state.info.modem_balance }}₴</span>
                          <span>1310.0Mb</span>
                        </div>-->
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </td>

            <td v-if="!userRoles.includes(rolesDictionary.ACCOUNTANT)">
              <div :class="[!servicePoint.terminals.length ? 'show-empty' : '', 'devices']">
                <div v-for="terminal in servicePoint.terminals" :key="terminal.id" class="devices-row">
                  <div class="devices-row-icons justify-space-around">
                    <v-tooltip :disabled="!showAllTooltips" right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="
                            openCommandDialog(terminal.id, terminal.state.status.is_online, 'Перезавантаження ЦП', 'RESET_CORE')
                          "
                          icon
                          x-small
                          v-on="on"
                          v-bind="attrs"
                        >
                          <v-icon>mdi-cog-refresh-outline</v-icon>
                        </v-btn>
                      </template>
                      <div class="text-tooltip">Перезавантаження центральної плати</div>
                    </v-tooltip>
                    <v-tooltip :disabled="!showAllTooltips" right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="openCommandDialog(terminal.id, terminal.state.status.is_online, 'Онулення', 'REFRESH')"
                          icon
                          x-small
                          v-on="on"
                          v-bind="attrs"
                        >
                          <v-icon>mdi-null</v-icon>
                        </v-btn>
                      </template>
                      <div class="text-tooltip">Онулення балансу автомату</div>
                    </v-tooltip>
                    <v-tooltip :disabled="!showAllTooltips" right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="
                            openCommandDialog(
                              terminal.id,
                              terminal.state.status.is_online,
                              'Технічний платіж',
                              'TECHNICAL_PAYMENT'
                            )
                          "
                          icon
                          x-small
                          v-on="on"
                          v-bind="attrs"
                        >
                          <v-icon>mdi-cash-refund</v-icon>
                        </v-btn>
                      </template>
                      <div class="text-tooltip">Технічний платіж</div>
                    </v-tooltip>
                    <v-tooltip :disabled="!showAllTooltips" right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="openCommandDialog(terminal.id, terminal.state.status.is_online, 'Блокування', 'BLOCK')"
                          icon
                          x-small
                          v-on="on"
                          v-bind="attrs"
                        >
                          <v-icon>mdi-lock-outline</v-icon>
                        </v-btn>
                      </template>
                      <div class="text-tooltip">Блокування чи розблокування автомату</div>
                    </v-tooltip>
                  </div>

                  <!--                  soft connected devices (на сервере) -->
                  <!-- TODO: check next condition for soft connected devices and try replace with 'is_hard_connected' status (). see 503 line  -->
                  <template v-if="!terminal.state || !terminal.state.devices">
                    <div v-for="device in terminal.devices" :key="device.id" class="devices-row-item">
                      <div class="type">
                        <span>{{ getDeviceTitle(device.type) }} {{ device.id }}</span>
                        <v-tooltip :disabled="!showAllTooltips" right>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-on="on" v-bind="attrs">#{{ device.connection_number }}</span>
                          </template>
                          <div class="text-tooltip">Номер автомату</div>
                        </v-tooltip>
                      </div>
                      <div class="status mt-2">
                        <img :src="require('../../assets/images/washing-machine-3.svg')" alt="machine" />
                        <div class="mt-2 text-caption">Підключений на сервері</div>
                        <div class="mt-2">Статуси не надходили</div>
                      </div>
                    </div>
                  </template>

                  <!--                 hard connected devices (на плате) -->
                  <template v-if="terminal.state">
                    <div v-for="device in terminal.state.devices" :key="device.id" class="devices-row-item">
                      <!-- *** WASHING_MACHINE *** -->
                      <template v-if="device.type === 'WM'">
                        <div class="type">
                          <router-link
                            v-if="device.id"
                            tag="span"
                            :to="{ name: 'DeviceCard', params: { id: currentCompanyId, deviceId: device.id } }"
                            v-add-class-on-hover="'text-decoration-underline'"
                            class="text-decoration-none"
                          >
                            {{ getDeviceTitle(device.type) }} {{ device.id }}
                          </router-link>
                          <span v-else>{{ getDeviceTitle(device.type) }} {{ device.id }}</span>
                          <v-tooltip :disabled="!showAllTooltips" top>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-on="on" v-bind="attrs">#{{ device.number }}</span>
                            </template>
                            <div class="text-tooltip">Номер автомату</div>
                          </v-tooltip>
                        </div>
                        <div class="status">
                          <div>
                            <v-tooltip :disabled="!showAllTooltips" top>
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-if="(device.id && device.is_hard_connected) || (!device.id && device.is_hard_connected)"
                                  v-on="on"
                                  v-bind="attrs"
                                >
                                  <span :style="{ color: device.is_online ? (device.rssi < -80 ? 'red' : 'green') : 'inherit' }">
                                    {{ device.rssi }}
                                  </span>
                                  <img :src="require('../../assets/images/network.svg')" height="10" alt="network" />
                                </span>
                              </template>
                              <div class="text-tooltip">Середній рівень сигналу з пралкою</div>
                            </v-tooltip>
                            <span v-if="device.device_spec_state">{{ device.display }}</span>
                            <span v-if="(device.id && device.is_hard_connected) || (!device.id && device.is_hard_connected)">
                              {{ device.balance }}₴
                            </span>
                          </div>
                          <template v-if="device.id && device.is_hard_connected">
                            <v-row no-gutters>
                              <v-col cols="4" offset="4">
                                <v-tooltip top open-delay="200">
                                  <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on" class="image">
                                      <span
                                        :style="{
                                          backgroundColor: device.is_online ? device.state_color : 'grey',
                                        }"
                                      ></span>
                                      <img
                                        :src="require('../../assets/images/washing-machine.svg')"
                                        class="d-block"
                                        alt="machine"
                                      />
                                    </div>
                                  </template>
                                  <div>
                                    <h4>Інфо про автомат:</h4>
                                    <p class="mb-0">
                                      ID: <span class="font-weight-medium">{{ device.id }}</span>
                                    </p>
                                    <p class="mb-0">
                                      Тип:
                                      <span class="font-weight-medium">
                                        {{ getMachineDescByTitle(device.type) }} ({{ device.type }})
                                      </span>
                                    </p>
                                    <p class="mb-0">
                                      Виробник:
                                      <span class="font-weight-medium">{{ device.manufacturer }}</span>
                                    </p>
                                    <p class="mb-0">
                                      Модель:
                                      <span class="font-weight-medium">{{ device.model }}</span>
                                    </p>
                                    <p class="mb-0">
                                      Дата виробництва:
                                      <span class="font-weight-medium">{{ device.manufacture_date }}</span>
                                    </p>
                                  </div>
                                </v-tooltip>
                              </v-col>
                              <v-col cols="4" class="text-left">
                                <img v-show="device.is_blocked" :src="require('../../assets/images/lock.svg')" alt="lock" />
                              </v-col>
                            </v-row>
                            <div class="status-tech">
                              <v-tooltip top :disabled="device.state.length < 50">
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on" class="d-block">{{ device.state }}</span>
                                  <div v-if="!device.is_online">
                                    <v-tooltip :disabled="!showAllTooltips" right>
                                      <template v-slot:activator="{ on, attrs }">
                                        <div class="d-flex justify-space-around" v-bind="attrs" v-on="on">
                                          <span>{{ device.last_packet_time | getShortDate }}</span>
                                          <span>{{ device.last_packet_time | getShortTime }}</span>
                                        </div>
                                      </template>
                                      <div class="text-tooltip">Час та дата останнього оновлення стану автомату</div>
                                    </v-tooltip>
                                  </div>
                                </template>
                                <span>{{ device.state }}</span>
                              </v-tooltip>
                            </div>
                            <div v-if="device.device_spec_state">{{ device.device_spec_state.wash_mode }}</div>
                          </template>
                          <template v-else-if="device.id && !device.is_hard_connected">
                            <!--                            <div style="visibility: hidden">hidden content</div>-->
                            <img :src="require('../../assets/images/washing-machine-3.svg')" alt="machine" />
                            <div class="mt-1 text-caption">Підключений на сервері</div>
                            <div class="mt-3">Статуси не надходили</div>
                          </template>
                          <template v-else>
                            <div class="text-caption" style="line-height: 1.3">Не підключений на сервері</div>
                            <div class="status-tech">
                              <v-tooltip top :disabled="device.state.length < 50">
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on" class="d-block">{{ device.state }}</span>
                                </template>
                                <span>{{ device.state }}</span>
                              </v-tooltip>
                            </div>
                            <div v-if="device.device_spec_state">{{ device.device_spec_state.wash_mode }}</div>
                          </template>
                        </div>
                        <div
                          v-if="(device.id && device.is_hard_connected) || (!device.id && device.is_hard_connected)"
                          class="mode"
                        >
                          <div v-if="device.device_spec_state">
                            <!--                          <img :src="require('../../assets/images/mode-1.svg')" height="13" alt="icon" />-->
                            <svg width="8" height="13" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M3.625 5.382V1C3.625 0.4485 3.12044 0 2.5 0C1.87956 0 1.375 0.4485 1.375 1V5.382C1.01556 5.667 0.8125 6.068 0.8125 6.5C0.8125 7.327 1.56962 8 2.5 8C3.43038 8 4.1875 7.327 4.1875 6.5C4.1875 6.068 3.98444 5.667 3.625 5.382ZM3.0625 2H2.78125C2.626 2 2.5 2.112 2.5 2.25C2.5 2.388 2.626 2.5 2.78125 2.5H3.0625V3.5H2.78125C2.626 3.5 2.5 3.612 2.5 3.75C2.5 3.888 2.626 4 2.78125 4H3.0625V4.5H1.9375V1C1.9375 0.7245 2.19006 0.5 2.5 0.5C2.80994 0.5 3.0625 0.7245 3.0625 1V2Z"
                                :fill="terminal.state.status.is_online ? '#18F8CE' : 'grey'"
                              />
                            </svg>
                            <span class="ml-1">{{ device.device_spec_state.wash_temp }}</span>
                          </div>
                          <div v-if="device.device_spec_state">
                            <!--                          <img :src="require('../../assets/images/mode-2.svg')" height="13" alt="icon" />-->
                            <svg width="12" height="12" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M6.98629 2.35827C5.96459 1.33656 4.30819 1.33656 3.2869 2.35828C2.4425 3.20227 2.4425 4.57143 3.28701 5.41551C3.98948 6.11798 5.12839 6.11798 5.83057 5.41538C6.41947 4.82647 6.41947 3.87141 5.83049 3.28244C5.33257 2.78453 4.52471 2.78453 4.02642 3.28233C3.89118 3.41744 3.89107 3.63661 4.02619 3.77185C4.1613 3.90709 4.38048 3.9072 4.51572 3.77208C4.74365 3.54435 5.11337 3.54435 5.34096 3.77196C5.65958 4.09058 5.65958 4.60732 5.34096 4.92594C4.90909 5.35807 4.2086 5.35807 3.77647 4.92594C3.20241 4.35217 3.20241 3.42154 3.7764 2.84782C4.52747 2.09647 5.74545 2.09647 6.49674 2.84776C7.46923 3.82056 7.46923 5.3976 6.49671 6.37043C5.247 7.62013 3.22073 7.62013 1.97099 6.3704C0.3751 4.7745 0.3751 2.1868 1.97099 0.590904C2.10617 0.45573 2.10617 0.236556 1.97099 0.101381C1.83582 -0.0337937 1.61664 -0.0337937 1.48147 0.101381C-0.384773 1.96762 -0.384773 4.99368 1.48147 6.85992C3.00157 8.38002 5.46618 8.38002 6.98626 6.85992C8.22909 5.61669 8.22909 3.60145 6.98629 2.35827Z"
                                :fill="terminal.state.status.is_online ? '#18F8CE' : 'grey'"
                              />
                            </svg>
                            <span class="ml-1">{{ device.device_spec_state.spin_mode }}</span>
                          </div>
                        </div>
                        <!--                      <div
                        v-if="(device.id && device.is_hard_connected) || (!device.id && device.is_hard_connected)"
                        class="balance"
                      >
                        <span>{{ device.rssi }}</span>
                        <span>{{ device.balance }}₴</span>
                      </div>-->
                      </template>
                      <!-- *** AQUA_MACHINE *** -->
                      <Aquamachine
                        v-if="device.type === 'AM'"
                        :show-all-tooltips="showAllTooltips"
                        :current-company-id="currentCompanyId"
                        :terminal="terminal"
                        :device="device"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </v-card>
    <div class="text-center">
      <v-row justify="end" class="mx-0 my-2">
        <div class="custom-pagination-select">
          <v-select v-model="itemsPerPage" @change="initialize" :items="itemsPerPageOptions" solo dense hide-details></v-select>
        </div>
        <v-pagination v-model="options.page" :length="getMonitoringListTotalPages" :total-visible="7"></v-pagination>
      </v-row>
    </div>
    <ConfirmActionModal @closeModal="closeCommandDialog" :showModal="showModal">
      <template v-slot:title>Відправити команду</template>
      <template v-slot:text>
        <div v-if="command !== 'BLOCK' && command !== 'UNBLOCK'" class="text-uppercase text-sm-center my-2">
          {{ commandTitle }}
        </div>
        <div v-else class="text-uppercase text-sm-center my-2">
          <v-btn @click="command = 'BLOCK'" :color="command === 'BLOCK' ? 'primary' : ''" class="mx-1" small depressed>
            Блокування
          </v-btn>
          <v-btn @click="command = 'UNBLOCK'" :color="command === 'UNBLOCK' ? 'primary' : ''" class="mx-1" small depressed>
            Розблокування
          </v-btn>
        </div>
        <div class="text-sm-center font-weight-bold mb-2">
          <v-chip
            @click="isTerminalSelected = !isTerminalSelected"
            class="ma-1"
            :disabled="command === 'TECHNICAL_PAYMENT'"
            :color="isTerminalSelected ? 'primary' : ''"
          >
            T{{ terminalId }}
          </v-chip>
        </div>
        <div v-if="allTerminalDevicesHardConnected.length">
          <v-chip
            v-for="device in allTerminalDevicesHardConnected"
            :key="device.id"
            @click="selectDevice(device)"
            class="ma-1"
            :color="device.selected ? 'primary' : device.is_blocked ? 'error' : ''"
            :disabled="
              ((command === 'BLOCK' || command === 'UNBLOCK') && !device.is_online) ||
              ((command === 'REFRESH' || command === 'TECHNICAL_PAYMENT') && !device.is_payable)
            "
          >
            {{ getDeviceTitle(device.type) }}{{ device.id }}
          </v-chip>
        </div>
        <div>
          <v-chip
            v-show="allTerminalDevicesHardConnected.length && isTerminalOnline"
            @click="selectAllDevices"
            class="mt-3 ml-1"
            :color="isAllDevicesSelected ? 'primary' : ''"
            outlined
          >
            Всі автомати
          </v-chip>
        </div>
        <div v-if="command === 'TECHNICAL_PAYMENT'">
          <v-text-field v-model.number="technicalPaymentAmount" class="mt-5" dense hide-details label="Сума, грн"></v-text-field>
        </div>
      </template>
      <template v-slot:buttons>
        <v-btn
          @click="sendCommandWithModal"
          :disabled="!isTerminalSelected && !selectedTerminalDevicesSet.size"
          color="primary"
          text
        >
          Так
        </v-btn>
        <v-btn @click="closeCommandDialog" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </v-container>
</template>

<script>
import CentrifugeApi from '@/services/CentrifugeApi'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import convertDate from '@/mixins/convertDate'
import convertCbStatusesTypes from '@/mixins/monitoring/convertCbStatusesTypes'
import { TERMINAL_COMMANDS, DEVICE_COMMANDS } from '@/const/commands'
import convertDevicesTypes from '@/mixins/convertDevicesTypes'
import range from 'lodash/range'
import FormToolbar from '@/components/common/forms/FormToolbar'
import AwaitingSearch from '@/mixins/constructor/awaitingSearch'
import sortUtils from '@/mixins/sortUtils'

export default {
  name: 'Monitoring',

  components: {
    FormToolbar,
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
    Aquamachine: () => import('@/components/monitoring/devices/Aquamachine'),
  },

  mixins: [convertDevicesTypes, convertDate, convertCbStatusesTypes, AwaitingSearch, sortUtils],

  data: () => ({
    baseURL: process.env.VUE_APP_BASE_API_URL,
    isLoaded: true,
    ws: null,
    terminalSubscriptions: [],
    counter: 0,
    showModal: false,
    commandTitle: '',
    command: '',
    technicalPaymentAmount: null,
    terminalId: null,
    isTerminalOnline: false,
    allTerminalDevices: [],
    selectedTerminalDevicesSet: new Set(),
    mySetChangeTracker: 1,
    isTerminalSelected: false,
    isAllDevicesSelected: false,
    options: {
      page: 1,
      sortBy: ['sort_number'],
      sortDesc: [false],
    },
    itemsPerPageOptions: [10, 20, 50, 100],
    itemsPerPage: JSON.parse(window.localStorage.getItem('monitoringItemsPerPage')) || 20,
    showAllTooltips: JSON.parse(window.localStorage.getItem('showMonitoringTooltips')) || false,
    search: '',
    tableHeader: [
      {
        name: 'number',
        title: '№',
      },
      {
        name: 'point',
        title: 'Торгівельна точка',
      },
      {
        name: 'finance',
        title: 'Фінансові дані',
      },
      {
        name: 'terminal',
        title: 'Термінал',
      },
      {
        name: 'devices',
        title: 'Автомати',
      },
    ],
  }),

  computed: {
    ...mapState('monitoring', ['monitoringList']),
    ...mapState('dictionaries', ['machinesTypesDictionary']),
    ...mapGetters('monitoring', ['getMonitoringListTotalPages', 'getMonitoringListTotalCount']),

    currentCompanyId() {
      return +this.$route.params.id
    },
    filteredTableHeader() {
      return this.tableHeader.filter((th) => {
        const isTechnician = this.userRoles.includes(this.rolesDictionary.TECHNICIAN)
        const isAccountant = this.userRoles.includes(this.rolesDictionary.ACCOUNTANT)
        return !!(
          (!isTechnician || (isTechnician && th.name !== 'finance')) &&
          (!isAccountant || (isAccountant && th.name !== 'terminal' && th.name !== 'devices'))
        )
      })
    },
    allTerminalDevicesHardConnected() {
      return this.allTerminalDevices.filter((device) => device.is_hard_connected)
    },
    selectedDevicesCSV() {
      return this.mySetChangeTracker && Array.from(this.selectedTerminalDevicesSet).join()
    },
    spCounters() {
      return range(this.getMonitoringListTotalCount + 1)
    },
    allTerminalsIMEIsPerPage() {
      return this.monitoringList.flatMap((point) => point.terminals.map((terminal) => terminal.imei))
    },
  },

  watch: {
    'options.page'() {
      this.initialize()
    },
    itemsPerPage(val) {
      window.localStorage.setItem('monitoringItemsPerPage', JSON.stringify(val))
    },
    showAllTooltips(val) {
      window.localStorage.setItem('showMonitoringTooltips', JSON.stringify(val))
    },
  },

  created() {
    this.initialize()
  },

  mounted() {
    this.setNavigationMenuVariant(true)
  },

  beforeDestroy() {
    this.disconnectFromWs()
  },

  methods: {
    ...mapActions('monitoring', ['loadMonitoringData', 'sendMonitoringTerminalCommand', 'sendMonitoringDeviceCommand']),
    ...mapActions('payments', ['createTechnicalPayment']),
    ...mapActions('servicePoints', ['changeServicePointSortingNumber']),
    ...mapMutations('common', ['SET_NAVIGATION_MENU_MINI']),

    async initialize() {
      this.isLoaded = false
      if (this.terminalSubscriptions.length) this.disconnectFromWs()
      const payload = {
        companyId: this.currentCompanyId,
        page: this.options.page,
        limit: this.itemsPerPage,
        sort: this.sortObject,
      }
      await this.loadMonitoringData(this.search ? { ...payload, search: this.search } : payload)
      await this.connectToWs()
      this.subscribeToSubscription()
      this.isLoaded = true
    },
    async paginateTo() {
      await this.initialize()
    },
    setNavigationMenuVariant(val) {
      this.SET_NAVIGATION_MENU_MINI(val)
    },
    async connectToWs() {
      // *** Initialize the Centrifuge connection
      this.ws = new CentrifugeApi()
      await this.ws.connectToCentrifuge()
    },
    subscribeToSubscription() {
      // *** Subscribe to terminal IMEIs
      this.terminalSubscriptions = this.allTerminalsIMEIsPerPage.map((imei) => {
        const subscription = this.ws.centrifuge.newSubscription(imei)
        subscription.on('publication', this.handlePublication)
        subscription.subscribe()
        return subscription
      })
    },
    unsubscribeFromSubscription() {
      this.terminalSubscriptions.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe()
          subscription.removeAllListeners()
        }
      })
    },
    disconnectFromWs() {
      // *** Unsubscribe and disconnect from Centrifuge
      this.unsubscribeFromSubscription()
      this.ws.disconnectFromCentrifuge()
    },
    handlePublication(ctx) {
      // *** Handle the publication data for a terminal
      const currentTerminalImei = ctx.data.info.imei
      this.monitoringList.forEach((point) => {
        if (point.terminals.length) {
          const currentTerminal = point.terminals.find((terminal) => terminal.imei === currentTerminalImei)
          if (currentTerminal) {
            ctx.data.devices.sort((a, b) => a.number - b.number)
            this.$set(
              point.terminals.find((terminal) => terminal.imei === currentTerminalImei),
              'state',
              ctx.data
            )
          }
        }
      })
    },
    selectDevice(device) {
      if (device?.selected) {
        this.$set(device, 'selected', !device.selected)
        this.selectedTerminalDevicesSet.delete(device.id)
      } else {
        this.$set(device, 'selected', true)
        this.selectedTerminalDevicesSet.add(device.id)
      }
      this.mySetChangeTracker++
    },
    selectAllDevices() {
      this.allTerminalDevicesHardConnected.forEach((device) => {
        this.$set(device, 'selected', !this.isAllDevicesSelected)
        if (!this.isAllDevicesSelected && device.is_payable) {
          this.selectedTerminalDevicesSet.add(device.id)
        } else {
          this.selectedTerminalDevicesSet.delete(device.id)
        }
      })
      this.isAllDevicesSelected = !this.isAllDevicesSelected
      this.mySetChangeTracker++
    },
    openCommandDialog(terminalId, isTerminalOnline, commandTitle, command) {
      this.terminalId = terminalId
      this.isTerminalOnline = isTerminalOnline
      this.commandTitle = commandTitle
      this.command = command
      if (command !== 'RESET_CORE') {
        this.monitoringList.forEach((point) => {
          if (point.terminals.length) {
            const currentTerminal = point.terminals.find((terminal) => terminal.id === terminalId)
            if (currentTerminal && currentTerminal.devices.length) {
              this.allTerminalDevices = [...currentTerminal.state.devices] || []
            }
          }
        })
      } else this.allTerminalDevices = []
      this.showModal = true
    },
    closeCommandDialog() {
      this.showModal = false
      this.allTerminalDevices.forEach((device) => {
        if (device?.selected) delete device.selected
      })
      this.isAllDevicesSelected = false
      this.isTerminalSelected = false
      this.selectedTerminalDevicesSet.clear()
      this.technicalPaymentAmount = null
    },
    async sendCommandWithModal() {
      this.showModal = false
      /* *** skeleton removed *** */
      // this.isLoaded = false
      if (this.command === 'TECHNICAL_PAYMENT' && (this.selectedDevicesCSV || this.isTerminalSelected)) {
        const payload = {
          companyId: this.currentCompanyId,
          devices: this.selectedDevicesCSV,
          terminals: this.isTerminalSelected ? this.terminalId : '',
          technicalPayment: {
            amount: this.technicalPaymentAmount,
          },
        }
        await this.createTechnicalPayment(payload)
      }
      if (this.isTerminalSelected && this.command !== 'TECHNICAL_PAYMENT') {
        const payload = {
          companyId: this.currentCompanyId,
          terminalId: this.terminalId,
          directiveTypeId: TERMINAL_COMMANDS[this.command],
        }
        await this.sendMonitoringTerminalCommand(payload)
      }
      if (this.command !== 'TECHNICAL_PAYMENT' && this.selectedTerminalDevicesSet.size) {
        const requests = []
        this.selectedTerminalDevicesSet.forEach((id) => {
          const payload = {
            companyId: this.currentCompanyId,
            terminalId: this.terminalId,
            deviceId: id,
            directiveTypeId: DEVICE_COMMANDS[this.command],
          }
          requests.push(this.sendMonitoringDeviceCommand(payload))
        })
        await Promise.all(requests)
      }
      this.closeCommandDialog()
      // this.isLoaded = true
    },
    async sendCommand(terminalId, command) {
      const payload = {
        companyId: this.currentCompanyId,
        terminalId,
        directiveTypeId: TERMINAL_COMMANDS[command],
      }
      await this.sendMonitoringTerminalCommand(payload)
    },
    getTerminalColor(terminal) {
      if (terminal.state.status.is_online) {
        return terminal.state.status.cb_colour || 'grey'
      } else {
        return 'grey'
      }
    },
    getPosTerminalIconColor(terminal) {
      if (terminal.state.status.is_online) {
        return terminal.state.status.pos_colour || 'grey'
      } else {
        return 'grey'
      }
    },
    getBillAcceptorIconColor(terminal) {
      if (terminal.state.status.is_online) {
        return terminal.state.status.ba_colour || 'grey'
      } else {
        return 'grey'
      }
    },
    async changeSpSortingNumber(point) {
      const payload = {
        companyId: this.currentCompanyId,
        id: point.id,
        sortNumber: point.sort_number,
      }
      await this.changeServicePointSortingNumber(payload)
      await this.initialize()
    },
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/table';
@import 'src/assets/scss/vars';

table {
  table-layout: auto;
  width: 100%;
}

tr {
  border-bottom: 4px solid $table-border-color;
  &:first-child {
    //border: none;
    border: 1px solid $table-border-color;
  }
  &:last-child {
    //border: none;
  }
}

th {
  &:nth-child(1) {
    width: 15px;
  }
  &:nth-child(2) {
    width: 180px;
  }
  &:nth-child(3) {
    //width: 180px;
  }
  &:nth-child(4) {
  }
  &:nth-child(5) {
  }
  &:nth-child(6) {
    //width: 120px;
  }
}

td {
  padding: 0;
  border-bottom: none;
  border-top: none;
}

.counter {
  ::v-deep .custom-v-select .v-input__control .v-select__selection {
    font-size: 14px;
  }
  ::v-deep .v-text-field > .v-input__control > .v-input__slot::before {
    border-style: none;
  }
  ::v-deep .v-text-field--filled {
    border-radius: 0;
  }
}

.balanceHolder {
  border-left: 1px solid $table-border-color;
  border-right: 1px solid $table-border-color;
}

.address {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-right: 30px;
  border-bottom: 3px double $table-border-color;
  font-size: 14px;
  &:last-child {
    border: none;
  }
}

.locations {
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
}

.location {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  writing-mode: vertical-rl;
  border: 1px solid $table-border-color;
  border-top-style: none;
  border-bottom: 3px double $table-border-color;
  span {
    font-size: 14px;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 150px;
    white-space: nowrap;
  }
  &:last-child {
    border-bottom: none;
  }
}

.finance {
  &-row {
    display: flex;
    border-bottom: 3px double $table-border-color;
    height: 160px;
    justify-content: center;
    &:last-child {
    }
    &-item {
      //flex: 1;
      //border-right: 1px solid $table-border-color;
      font-size: 14px;
      text-align: center;
      padding-top: 30px;
      &:last-child {
        border: none;
      }
    }
  }
  &:last-child {
    .finance-row {
      &:last-child {
        border: none;
      }
    }
  }
}
.terminal {
  &-row {
    display: flex;
    border-bottom: 3px double $table-border-color;
    height: 160px;
    &:last-child {
    }
    &-item {
      flex: 1;
      width: 120px;
      min-width: 120px;
      border-right: 1px solid $table-border-color;
      padding: 5px;
      &:last-child {
        border: none;
      }
      &__header {
        display: flex;
        justify-content: flex-start;
        //align-items: flex-end;
        align-items: center;
        & > div {
          flex: 1;
          &:first-child {
            text-align: left;
          }
          &:last-child {
            text-align: right;
          }
        }
        span {
          font-size: 20px;
          font-weight: 700;
        }
      }
      &__imei {
        margin-top: 10px;
        font-size: 10px;
        font-weight: 700;
      }
      &__status {
        font-size: 12px;
        font-weight: 700;
        padding: 10px 0;
        //border-bottom: 1px solid $table-border-color;
      }
      &__statuses {
        text-align: left;
      }
    }
    .right {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      & > div {
        &:nth-child(1) {
          display: flex;
          flex: 1;
          justify-content: space-around;
          align-items: center;
          span {
            font-size: 11px;
            font-weight: 600;
          }
        }
        &:nth-child(2) {
          border-top: 1px solid $table-border-color;
          display: flex;
          flex: 1;
          justify-content: space-around;
          align-items: center;
          span {
            font-size: 14px;
            font-weight: 400;
          }
          img {
            max-width: 15px;
          }
        }
        &:nth-child(3) {
          border-top: 1px solid $table-border-color;
          .mobile {
            font-size: 12px;
            font-weight: 200;
            margin-top: 3px;
          }
          .network {
            display: flex;
            justify-content: space-between;
            border: none;
            span {
              &:first-child {
                font-size: 12px;
                font-weight: 300;
                img {
                  position: relative;
                  top: -5px;
                  right: 2px;
                  width: 10px;
                }
              }
              &:last-child {
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
          .report {
            display: flex;
            justify-content: space-between;
            border: none;
            span {
              font-size: 10px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
  &:last-child {
    .terminal-row {
      &:last-child {
        border: none;
      }
    }
  }
}

.devices {
  &-row {
    display: flex;
    border-bottom: 3px double $table-border-color;
    height: 160px;
    &:last-child {
      //border-bottom: 8px double $table-border-color;
      //border: none;
    }
    &-icons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 40px;
      border-right: 1px solid $table-border-color;
      img {
        display: block;
        width: 20px;
        height: auto;
        margin: 5px 0;
      }
    }
    &-item {
      //flex: 1;
      //max-width: 120px;
      width: 160px;
      border-right: 1px solid $table-border-color;
      padding: 5px;
      &:last-child:not(:first-child) {
        //border: none;
      }
    }
    .type {
      display: flex;
      justify-content: space-between;
      span {
        &:first-child {
          font-size: 10px;
          font-weight: 200;
          cursor: pointer;
        }
        &:last-child {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
    .status {
      &-tech {
        font-size: 12px;
        //font-weight: 500;
        height: 36px;
        margin-top: 5px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      img {
        //display: block;
        //margin: 0 auto;
      }
      & > div {
        &:first-child {
          display: flex;
          flex: 1;
          justify-content: space-between;
          font-size: 9px;
          font-weight: 400;
          min-height: 14px;
          span {
            flex-basis: 0;
            flex-grow: 1;
          }
        }
        &:last-child {
          font-size: 10px;
          font-weight: 100;
          min-height: 15px;
        }
      }
    }
    .image {
      display: inline-block;
      position: relative;
      span {
        position: absolute;
        left: calc(50% - 14px / 2);
        top: calc(50% - 14px / 2 + 2px);
        width: 14px;
        height: 14px;
        border-radius: 50%;
        z-index: 0;
      }
      img {
        width: 26px;
        height: auto;
        position: relative;
        z-index: 1;
      }
    }
    .mode {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 10px;
        font-weight: 200;
      }
    }
    .balance {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        &:first-child {
          font-size: 10px;
          font-weight: 200;
        }
        &:last-child {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
  &:last-child {
    .devices-row {
      &:last-child {
        border: none;
      }
    }
  }
}

/*.status-active {
  color: $teal;
}*/

.status-err {
  color: $red;
}

.show-empty {
  height: 160px;
}

.custom-pagination-select {
  width: 85px;
  ::v-deep .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 31px !important;
    margin-top: 7px;
  }
}

.text-tooltip {
  max-width: 164px;
}
</style>
